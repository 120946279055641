import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Dialog from '@radix-ui/react-dialog';
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import '../css/radixdialog.css';
import "../css/imagecarddialog.css";
import { LsIcons } from './ui/LsIcons';

const ExampleImageCard = ({ id, image, prompt, negativePrompt, onRegenerate }) => {
  const [showImage, setShowImage] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [dialogSize, setDialogSize] = useState({ width: 'auto', height: 'auto' });

  useEffect(() => {
    if (showImage) {
      const img = new Image();
      img.src = image;
      img.onload = () => {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const imgWidth = img.width;
        const imgHeight = img.height;

        let width, height;

        if (imgWidth > screenWidth || imgHeight > screenHeight) {
          const widthRatio = screenWidth / imgWidth;
          const heightRatio = screenHeight / imgHeight;
          const ratio = Math.min(widthRatio, heightRatio) * 0.9; // 90% of the screen
          width = imgWidth * ratio;
          height = imgHeight * ratio;
        } else {
          width = imgWidth;
          height = imgHeight;
        }

        setDialogSize({ width, height });
      };
      img.onerror = () => {
        console.error('Image failed to load:', image);
      };
    }
  }, [showImage, image]);

  return (
    <div>
      <div className="example-image-card">
        <div className="card-content">
          <img
            src={image}
            className="card-img"
            alt="Example"
            onClick={() => setShowImage(true)}
          />
          <div className="card-overlay">
            <div className="card-buttons">
              <button
                className="card-button info-button"
                onClick={() => setShowInfo(true)}
              >
                {LsIcons.Text2Image_info_icon}
              </button>
              <button
                className="card-button regenerate-button"
                onClick={() => onRegenerate(prompt, negativePrompt)}
              >
                {LsIcons.AIChatComponent_regenerate_svg_icon}
              </button>
              {id !== undefined && (
                <Link to={`/image-example/${id}`} className="card-button detail-button">
                {LsIcons.Playground_share_config_withkey_svg_icon}
              </Link>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Info Dialog */}
      <Dialog.Root open={showInfo} onOpenChange={setShowInfo}>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">
            <Dialog.Title className="DialogTitle">Prompt Info</Dialog.Title>
            <Dialog.Description className="DialogDescription">
              Details about the prompt used to generate this image.
            </Dialog.Description>
            <div className="flex flex-col space-y-4">
              <div className="flex items-center justify-between">
                <label className="font-bold">Prompt</label>
                <button className="Button small" onClick={() => navigator.clipboard.writeText(prompt || '')}>
                  {LsIcons.Text2Image_copy_svg}
                </button>
              </div>
              <textarea
                className="border p-2 rounded w-full"
                rows={Math.min(Math.ceil((prompt || '').length / 45), 6)}
                readOnly
                value={prompt || ''}
              />
              <div>
                <label className="font-bold">Negative Prompt</label>
                <textarea
                  className="border p-2 rounded w-full"
                  rows={Math.min(Math.ceil((negativePrompt || '').length / 45), 6)}
                  readOnly
                  value={negativePrompt || ''}
                />
              </div>
            </div>
            <Dialog.Close asChild>
              <button className="Button green mt-4">
                Close
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      {/* Image Dialog */}
      <Dialog.Root open={showImage} onOpenChange={setShowImage}>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlayImg" />
          <Dialog.Content
            className="DialogImageContent"
            style={{ width: dialogSize.width, height: dialogSize.height }}
          >
            <Dialog.Title>
              <VisuallyHidden.Root>Image Preview</VisuallyHidden.Root>
            </Dialog.Title>
            <Dialog.Description>
              <VisuallyHidden.Root>
                Full-size preview of the generated image. Use pinch or scroll to zoom, drag to pan.
              </VisuallyHidden.Root>
            </Dialog.Description>
            <TransformWrapper>
              <TransformComponent>
                <img src={image} alt="Example" className="w-full h-auto rounded-lg" />
              </TransformComponent>
            </TransformWrapper>
            <Dialog.Close asChild>
              <button className="DialogImageCloseButton" aria-label="Close">
                &#x2715;
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};

export default ExampleImageCard;
