import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { FaGithub, FaTwitter, FaDiscord, FaReddit } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";
import axios from 'axios';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const SocialButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0 10px;
  background-color: #333;
  color: white;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  &.twitter {
    background-color: #1DA1F2;
    animation: ${pulse} 2s infinite;
  }

  &.github {
    background-color: #24292e;
  }

  &.discord {
    background-color: #7289DA;
  }

  &.reddit {
    background-color: #FF4500;
  }
`;

const About = () => {
  const location = useLocation();
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Chat stock Component" });
  }, [location]);

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_CMS_API_BASE_URL}/aboutpage`);
        setMarkdown(response.data.content);
      } catch (error) {
        console.error('Error fetching markdown content:', error);
      }
    };

    fetchMarkdown();
  }, []);

  const renderers = {
    link: ({node, ...props}) => <a target="_blank" rel="noopener noreferrer" {...props} />
  };

  return (
    <div className="p-6">
      <div className="flex justify-center mt-8 mb-8">
        <SocialButton href="https://github.com/flowinginthewind700/llmstock_issues" target="_blank" rel="noopener noreferrer" className="github">
          <FaGithub size={30} />
        </SocialButton>
        <SocialButton href="https://x.com/McQueenFu" target="_blank" rel="noopener noreferrer" className="twitter">
          <FaTwitter size={30} />
        </SocialButton>
        <SocialButton href="https://discord.com/invite/GA9Hmwy5Y9" target="_blank" rel="noopener noreferrer" className="discord">
          <FaDiscord size={30} />
        </SocialButton>
        {/* <SocialButton href="https://www.reddit.com/r/FinanceGPT/" target="_blank" rel="noopener noreferrer" className="reddit">
          <FaReddit size={30} />
        </SocialButton> */}
      </div>
      <ReactMarkdown className="post-markdown text-left" components={renderers}>{markdown}</ReactMarkdown>
    </div>
  );
};

export default About;
