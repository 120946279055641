import React, { memo, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { LsIcons } from "@/components/ui/LsIcons";
import html2canvas from 'html2canvas';
import ReactGA from "react-ga4";
import mermaid from 'mermaid';
import Prism from 'prismjs';
import { QRCodeCanvas } from 'qrcode.react';
import '../css/post-detail.css';
import '../css/mermaid.css';
import '../css/markdown-styles.css';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import 'highlight.js/styles/a11y-dark.css';
import CodePre from './ui/CodePre';
import "../css/market.css";
import { motion } from 'framer-motion';
import * as Tooltip from '@radix-ui/react-tooltip';
import ImagePreview from './ImagePreview';
import ShareButtons from './social/ShareButtons';
import SEO from './seo/SEO';
import ContentAdComponent from './ContentAdComponent'; // Import the new ad component

const BilibiliEmbed = ({ videoId }) => (
  <div className="video-responsive">
    <iframe
      src={`https://player.bilibili.com/player.html?bvid=${videoId}&page=1`}
      scrolling="no"
      border="0"
      frameBorder="no"
      framespacing="0"
      allowFullScreen={true}
      title="Embedded bilibili"
    />
  </div>
);

const YouTubeEmbed = ({ videoId }) => (
  <div className="video-responsive">
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${videoId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

const VideoEmbed = ({ videoUrl }) => (
  <div className="video-responsive">
    <video controls>
      <source src={videoUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
);

const CodeRenderer = memo(({ node, inline, className = "blog-code", children, ...props }) => {
  const match = /language-(\w+)/.exec(className || '');
  return !inline && match ? (
    <SyntaxHighlighter
      style={a11yDark}
      language={match[1]}
      PreTag="div"
      {...props}
      codeTagProps={{
        className: 'custom-code',
      }}
    >
      {String(children).replace(/\n$/, '')}
    </SyntaxHighlighter>
  ) : (
    <code className={`custom-code ${className}`} {...props}>
      {children}
    </code>
  );
});

const BlockNode = ({ node, ...props }) => {
  const { className } = props;

  useEffect(() => {
    if (className === 'mermaid') {
      mermaid.init(undefined, `.${className}`);
    }
  }, [className]);

  if (className === 'mermaid') {
    return <div className="mermaid">{props.children}</div>;
  }

  if (className === 'youtube') {
    const youtubeVideoId = node.properties.videoid || node.properties.videoId;
    return youtubeVideoId ? <YouTubeEmbed videoId={youtubeVideoId} /> : null;
  }

  if (className === 'bilibili') {
    const bilibiliVideoId = node.properties.videoid || node.properties.videoId;
    return bilibiliVideoId ? <BilibiliEmbed videoId={bilibiliVideoId} /> : null;
  }

  if (className === 'video') {
    const videoUrl = node.properties.url || node.properties.src;
    return videoUrl ? <VideoEmbed videoUrl={videoUrl} /> : null;
  }

  return <div className={className}>{props.children}</div>;
};

const AnimatedButton = () => (
  <Tooltip.Provider>
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <motion.a
          href="https://llmstock.com/specialoffer"
          target="_blank"
          rel="noopener noreferrer"
          className="px-1 py-1 rounded m-1 button-uniform"
          whileHover={{ scale: 1.1, rotate: 360 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: "spring", stiffness: 400, damping: 17 }}
        >
          <motion.img
            src="/icons/dollar-symbol-money.svg"
            alt="Trade"
            className="w-6 h-6"
            initial={{ opacity: 0.6 }}
            animate={{ opacity: 1 }}
            transition={{ yoyo: Infinity, duration: 2 }}
          />
        </motion.a>
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content className="ms-TooltipContent" sideOffset={5}>
          Open trade account now
          <Tooltip.Arrow className="ms-TooltipArrow" />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  </Tooltip.Provider>
);

const PostDetail = () => {
  const location = useLocation();
  ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Post Detail" });
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const apiUrl = process.env.REACT_APP_CMS_API_BASE_URL;
  const navigate = useNavigate();
  const postDetailRef = useRef(null);

  const currentURL = `https://llmstock.com${location.pathname}`;

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`${apiUrl}/posts/${id}`);
        setPost(response.data);
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    fetchPost();
    mermaid.initialize({ startOnLoad: true });
    Prism.highlightAll();
  }, [id, apiUrl]);

  const handleScreenshot = async () => {
    if (!postDetailRef.current) return;
    const canvas = await html2canvas(postDetailRef.current, {
      scrollX: 0,
      scrollY: -window.scrollY,
      useCORS: true,
    });
    const context = canvas.getContext('2d');
    context.font = '30px Arial';
    context.fillStyle = 'rgba(255, 255, 255, 0.5)';
    context.fillText('llmstock.com', 20, canvas.height - 30);
    const link = document.createElement('a');
    const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
    link.href = canvas.toDataURL('image/png');
    link.download = `screenshot_${timestamp}.png`;
    link.click();
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n-1) + '...' : str;
  };

  const renderAuthorInfo = () => {
    if (post.author) {
      return (
        <div className="flex items-center mt-4">
          <img
            src={post.author.avatar}
            alt={post.author.name}
            className="w-10 h-10 rounded-full mr-3"
          />
          <div>
            <p className="font-semibold">{post.author.name}</p>
            <p className="text-gray-500">
              {new Date(post.date).toLocaleDateString()}
              {' • '}
              {post.author.twitter && (
                <a 
                  href={`https://twitter.com/intent/follow?screen_name=${post.author.twitter}`}
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  Follow on Twitter
                </a>
              )}
            </p>
          </div>
        </div>
      );
    }
    return (
      <div className="mt-4">
        <p className="text-gray-500">{new Date(post.date).toLocaleDateString()} from llmstock.com</p>
      </div>
    );
  };
  

  if (!post) {
    return <div>Loading...</div>;
  }

  const shareUrl = currentURL;
  const shareTitle = post.title;
  const shareDescription = truncate(post.description, 100);
  const shareImage = post.cover?.length > 0 ? `${apiUrl}${post.cover[0].url}` : '';

  return (
    <>
      <SEO
        title={shareTitle}
        description={shareDescription}
        image={shareImage}
      />
      <div className="container mx-auto px-4 sm:px-6 md:px-8 lg:px-10" ref={postDetailRef} style={{ overflowX: 'auto' }}>
        <div className="max-w-3xl mx-auto">
          <div className="flex justify-start mb-4 items-center">
            <button
              onClick={() => navigate('/blog')}
              className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600 mr-2 flex items-center button-uniform"
            >
              {LsIcons.Back_svg_icon} <span className="ml-2">llmstock.com</span>
            </button>
            <button
              onClick={handleScreenshot}
              className="bg-green-500 text-white px-2 py-2 rounded hover:bg-green-600 mr-2 button-uniform"
            >
              {LsIcons.Camera_svg_icon}
            </button>
            {post.category && post.category.slug && ['market', 'stock'].includes(post.category.slug) && <AnimatedButton />}
          </div>

          {post.cover?.length > 0 && (
            <div className="mb-4 overflow-hidden rounded-lg" style={{ maxHeight: '400px' }}>
              <img
                src={`${apiUrl}${post.cover[0].url}`}
                alt={post.title}
                className="w-full h-full object-cover cursor-pointer"
                onClick={() => handleImageClick(`${apiUrl}${post.cover[0].url}`)}
              />
            </div>
          )}
          <ShareButtons 
            url={shareUrl}
            title={shareTitle}
            description={shareDescription}
            image={shareImage}
          />
          <h1 className="text-3xl font-bold mb-4 break-words text-left">{post.title}</h1>

          <div className="mb-8 p-6 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg">
            <p className="text-lg font-semibold text-gray-800 mb-2">Summary:</p>
            <p className="text-gray-700 break-words leading-6">{post.description}</p>
          </div>

          {/* Here we use the new ContentAdComponent to load ads */}
          <ContentAdComponent adSlot='1527445564' />
          
          <ReactMarkdown
            className="post-markdown text-left"
            linkTarget="_blank"
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}
            components={{
              pre: CodePre,
              code: CodeRenderer,
              div: ({ node, ...props }) => <BlockNode node={node} {...props} />,
              img: ({ node, ...props }) => (
                <img
                  {...props}
                  className="cursor-pointer"
                  onClick={() => handleImageClick(props.src)}
                />
              ),
              a: ({ node, ...props }) => (
                <a {...props} target="_blank" rel="noopener noreferrer">{props.children}</a>
              )
            }}
          >
            {post.content}
          </ReactMarkdown>

          {renderAuthorInfo()}

          {/* Another ad positioned after the post content */}
          <ContentAdComponent adSlot='1438290543' />

          <div className="flex justify-center mt-8">
            <QRCodeCanvas value="https://llmstock.com" size={128} level="H" includeMargin={true} />
          </div>
        </div>
      </div>

      {selectedImage && (
        <ImagePreview
          imageUrl={selectedImage}
          altText="Preview"
          isOpen={!!selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </>
  );
};

export default PostDetail;
